import React, { Component } from 'react';
import './App.css';
import ImageContainer from './Components/ImageContainer';
import btn_icon_365297 from './images/btn_icon_365297.png';
import btn_icon_615817 from './images/btn_icon_615817.png';
import btn_icon_804089 from './images/btn_icon_804089.png';
import checkIcon from './images/checkIconGreen.png';
import xIcon from './images/xIcon2.png';
import ReactLoading from 'react-loading';

import loadImage from '../node_modules/blueimp-load-image/js'

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'
import 'firebase/storage'

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MainPageScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      images : [], // all images from file inputs will be pushed onto here via onChangeClick
      actualImages: [], // image files to be uploaded to firebase
      croppedImages: [],
      urls : [],
      ids: [],
      grayScreen: false,
      loading: false,
      loadingImage: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1
      }
    }
  }

  componentDidMount() {
    const queryString = require('query-string');
    const parsed = queryString.parse(window.location.search);

    let self = this
    if (parsed["patient"] && parsed["doc"] && parsed["date"] && parsed["patient2"]){
      self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientList/' + parsed["patient"]).once("value", snapshot => {
        if (snapshot.exists()) {
          let date = parsed["date"]
          let stopTime = parseInt(date) + 3600
          let nowTime = Math.floor(Date.now()/1000)
          if (nowTime > stopTime) {
            if (this.state.isActive) {
              self.setState({
                isActive:false
              })
            }
          } else {
            if (this.state.isActive) {} else {
              self.setState({
                isActive:true
              })
            }
          }
        }
      })
    }
    // if (parsed["patient"]) {
    //   if (parsed["doc"]) {
    //     if (parsed["date"]) {
    //       if (parsed["patient2"]) {
    //         self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientList/' + parsed["patient"]).once("value", snapshot => {
    //           if (snapshot.exists()) {
    //             let date = parsed["date"]
    //             let stopTime = parseInt(date) + 3600
    //             let nowTime = Math.floor(Date.now()/1000)
    //             if (nowTime > stopTime) {
    //               if (this.state.isActive) {
    //                 self.setState({
    //                   isActive:false
    //                 })
    //               }
    //             } else {
    //               if (this.state.isActive) {} else {
    //                 self.setState({
    //                   isActive:true
    //                 })
    //               }
    //             }
    //           }e
    //         })
    //       }
    //     }
    //   }
    // }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // if ( this.state.cropHasBeenAdjusted ) {
      this.setState({ crop: percentCrop })
    // } else {
    //   this.setState({ cropHasBeenAdjusted: true, crop: { ...percentCrop, unit: '%', x: 50 - (percentCrop.width / 2), y: 50 - (percentCrop.height / 2)  } });
    // }
    // // this.setState({ crop });
  };

  onClickDone = () => {
    const newImages = [...this.state.croppedImages, this.state.croppedImageUrl]
    this.setState({...this.state, cropHasBeenAdjusted: false, croppedImages: newImages })
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_mainPageState', newVal);
  }

  onClickCancel = () => {
    const newActualImages = this.state.actualImages.slice(0, this.state.actualImages.length - 1)

    this.setState({ cropHasBeenAdjusted: false, actualImages: newActualImages })
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_mainPageState', newVal);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {

      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      const croppedImageUrl = croppedImage[0]
      const croppedImageFile = croppedImage[1]

      const newActualImages = this.state.actualImages
      newActualImages[newActualImages.length - 1] = croppedImageFile
      this.setState({ croppedImageUrl, actualImages: newActualImages });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const maxWidth = 960
    const shrinkRatio = maxWidth / image.naturalWidth
    
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio);
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio);
    } else {
      canvas.width = Math.floor(crop.width * scaleX);
      canvas.height = Math.floor(crop.height * scaleY);
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    
    const screenWidth = window.innerWidth

    // const minWidth = Math.ceil(192000 / image.naturalWidth)
    const minWidth =  480 / scaleX / shrinkRatio

    this.setState({ minWidth })

    if (image.naturalWidth > maxWidth) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX * shrinkRatio,
        crop.height * scaleY * shrinkRatio,
      );
  
    } else {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve([this.fileUrl, blob]);
      }, 'image/jpeg');
    });
  }

  removeImage = (input) => {
    let newimages = this.state.images
    let newCroppedImages = this.state.croppedImages
    let actualImages = this.state.actualImages

    newimages.splice(input,1)
    newCroppedImages.splice(input,1)
    actualImages.splice(input,1)
    this.setState({
      images: newimages,
      croppedImages: newCroppedImages,
      actualImages: actualImages
    })
  }

  onChangeClick0 = e => {
    this.setState({ loadingImage: true })

    let newVal = "4";
    this.props.appActions.updateDataSlot('ds_mainPageState', newVal);

    const file = e.target.files[0]

    let imgUrl
    function detectApple() {
      const toMatch = [
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
      ];
  
      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });
    }
    const orientation = detectApple() ? { orientation: 1 } : { orientation: true }
    loadImage( e.target.files[0], (img) => {
      const blobPromise = new Promise((resolve, reject) => {
        if (img.height < 480 || img.width < 480) {
          alert("Warning: Image should be at least 480 x 480 pixels. Choose a photo with higher resolution for greater accuracy.")
          // this.setState({ tooSmall: true })
        }
        //  else {
        //   this.setState({ tooSmall: false })
        // }
        img.toBlob(blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'fileName';
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([this.fileUrl, blob]);
        }, 'image/jpeg');
      });

      blobPromise
        .then((imageArr) => {
          this.setState({
            images: [...this.state.images, imageArr[0]],
            actualImages: [...this.state.actualImages, imageArr[1]],
            crop: {
              unit: '%',
              height: 100,
              width: 100,
              aspect: 1 / 1
            },
            loadingImage: false })
        })
    }, orientation );
  }

  onClick_state0_elAddPhoto = (ev) => {
    if (this.state.isActive) {
      document.getElementById("Test250").click()
    } else {
      alert("It seems that you are not allowed to use this feature at the moment.")
    }
  }

  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+ this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const interactEl = {
      display: 'block',
      cursor: 'pointer',
      pointerEvents: 'auto',
      backgroundColor: '#123456'
    }
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      backgroundColor: 'rgba(18, 18, 18, 0.4900)',
     };
    const style_state0_elContainerCard_outer = {
      backgroundColor: '#feffff'
     };
    const style_state0_elTitleText = {
      fontSize: 19.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elInstructionsText = {
      fontSize: 14.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state0_elAddPhoto = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 13.3,
      color: 'black',
      textAlign: 'center',
      backgroundColor: '#8ee9ff',
      cursor: 'pointer',
      pointerEvents: 'auto'
     };
    const style_state0_elTakePhoto = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 13.3,
      color: '#8ee9ff',
      textAlign: 'center',
      backgroundColor: '#feffff',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    return (
      <div className="AppScreen MainPageScreen" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight state0_elBackground' style={style_state0_elBackground_outer}>
            <div className="" style={style_state0_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="flowRow flowRow_MainPageScreen_state0_elContainerCard_884287">
          <div className='state0_elContainerCard' style={style_state0_elContainerCard_outer}>
            <div className="" />
          </div>

          </div>
          <div className='state0_elTitleText'>
            <div className="font-segoeUISemibold" style={style_state0_elTitleText}>
              <div>{this.props.locStrings.mainpage_text_521234}</div>
            </div>
          </div>

          <div className='state0_elInstructionsText'>
            <div className="font-segoeUI" style={style_state0_elInstructionsText}>
              <div>To get started, please take photos of any mole you are concerned with. You can also use photos which you have previously taken.<br/><br/> Once you are ready click the submit button.</div>
            </div>
          </div>

          <div className='state0_elAddPhoto'>
            <button className="font-poppinsSemiBold" style={style_state0_elAddPhoto} onClick={this.onClick_state0_elAddPhoto} >
              {this.props.locStrings.mainpage_button_282064}
            </button>
          </div>
          <input id="Test250" hidden type="file" accept="image/*" onChange={this.onChangeClick0}/>
        </div>
      </div>
    )
  }

  // --- Functions for component state index 1 (2 of 5) ---

  onClick_SubmitPhotos = (ev) => {
    this.setState({
      grayScreen:true,
      loading: true
    })
    const uuidv4 = require('uuid/v4');

    let storageRef = this.props.storage.ref("Tests")
    let self = this
    if (this.state.actualImages.length > 0) {
      let testnames = []
      for (let i = 0; i < this.state.actualImages.length; i++) {
        let pushName = uuidv4()
        testnames.push(pushName)
        
      }
      this.state.actualImages.forEach((image,index) => {
        let testname = testnames[index]
        let newRef = storageRef.child(testname).put(image).then((snapshot) =>
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            let urls = self.state.urls
            let ids = self.state.ids
            urls.push(downloadURL)
            ids.push(testname)
            self.setState({
              urls : urls,
              ids : ids
            })
            let newDate = new Date()
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let hour = newDate.getHours();
            let min = newDate.getMinutes();
            let sec = newDate.getSeconds();
            self.props.firestore.collection("Tests").doc(`${testname}`).set({
              DateTaken : `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`,
              ImageRef : downloadURL,
              Source: 'Physician'
            })

            if (self.state.urls.length === self.state.actualImages.length) {
              setTimeout(function() {
                const queryString = require('query-string');
              const parsed = queryString.parse(window.location.search);
              (async () => {


                let dataToSend = JSON.stringify({
                  patient : parsed["patient2"],
                  ids : testnames
                })
                console.log('data to send', dataToSend)
                const rawResponse = await fetch('https://us-central1-raw-compute.cloudfunctions.net/dermato-1', {
                  method: 'POST',
                  body: dataToSend
                });
                const content = await rawResponse.json();


                if (content["message"] == "Success!") {
                  let newDate = new Date()
                  let date = newDate.getDate();
                  let month = newDate.getMonth() + 1;
                  let year = newDate.getFullYear();
                  let hour = newDate.getHours();
                  let min = newDate.getMinutes();
                  let sec = newDate.getSeconds();


                  self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/Patients/'+ parsed["patient"]).update({
                    LastUpdate : `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`
                  })
                  self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientCount/' + year + '/' + month + "/Patients").once("value", snapshot => {
                    if (snapshot.exists()) {
                      let dataToUse = snapshot.val()
                      if (dataToUse.includes(parsed["patient"])) {
                      } else {
                        let numInput = String(dataToUse.length)
                        self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientCount/' + year + '/' + month + "/Patients/" + numInput).set(parsed["patient"])
                      }
                    } else {
                      self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientCount/' + year + '/' + month + "/Patients/0").set(parsed["patient"])
                    }
                  })

                  self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientCount/' + year + "/Patients").once("value", snapshot => {
                    if (snapshot.exists()) {
                      let dataToUse = snapshot.val()
                      if (dataToUse.includes(parsed["patient"])) {
                      } else {
                        let numInput = String(dataToUse.length)
                        self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientCount/' + year + "/Patients/" + numInput).set(parsed["patient"])
                      }
                    } else {
                      self.props.database.ref('Dermatology/Physicians/' + parsed["doc"] + '/PatientCount/' + year + "/Patients/0").set(parsed["patient"])
                    }
                  })
                  self.setState({
                    grayScreen:false,
                    loading: false,
                    images : [], // all images from file inputs will be pushed onto here via onChangeClick
                    actualImages: [], // image files to be uploaded to firebase
                    croppedImages: [],
                    urls : [],
                  })
                  let newVal = "2";
                  self.props.appActions.updateDataSlot('ds_mainPageState', newVal);

                } else {
                  self.setState({
                    grayScreen:false,
                    loading: false
                  })
                  alert("There was an error submitting your tests.")
                }



              })();
              }, 1000)

            }
          })
        )
      })
    } else {
      alert("There is no test to submit.")
      self.setState({
        grayScreen:false,
        loading: false
      })
    }
  }

  onClick_AddPhoto = (ev) => {
    document.getElementById("Test33").click()
  }

  renderState1() {
    let layoutFlowStyle = {
      // botSpacer : "100px"
    };
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_state1_elBackground35134 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground35134_outer = {
      backgroundColor: '#feffff',
     };
    const style_state1_elList638512 = {
      height: 'auto',  // This element is in scroll flow
     };

    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};

    const spacer = {
      height: "10px"
    }
    const botSpacer = {
      height: '100px'
    }
    const style_state1_elTopContainer385950 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elTopContainer385950_outer = {
      backgroundColor: '#feffff'
     };
    const style_state1_elText948541 = {
      fontSize: 17.1,
      color: '#37404f',
      textAlign: 'center',
     };
    const style_state1_elBottomContainer628788 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBottomContainer628788_outer = {
      backgroundColor: '#feffff'
     };
    const style_state1_elButton365297 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_365297+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '36.000%',
      paddingTop: '35px',
      backgroundPosition: '50% 0%',
      color: '#37404f',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elButtonCopy615817 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_615817+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '38.500%',
      paddingTop: '35px',
      backgroundPosition: '50% 0%',
      color: '#37404f',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

     const GrayStyle = {
      background: 'rgba(169, 169, 169, 1.000)',
      opacity: 0.60,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

     const GrayScreen = this.state.grayScreen ? (
      <div className="GrayScreen" style={GrayStyle}/>
     ) : null;

     const LoadingCircle = this.state.loading ? (
      <ReactLoading type={"spin"} height={'20%'} width={'20%'} className={"Loading"}/>
    ) : null;

    return (
      <div className="AppScreen MainPageScreen" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight state1_elBackground35134' style={style_state1_elBackground35134_outer}>
            <div className="" style={style_state1_elBackground35134} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div style={spacer}/>
          <div className='hasNestedComps state1_elList638512'>

            <div className="" style={style_state1_elList638512}>
              {this.state.croppedImages.map((row, index) => {
                let itemClasses = `gridItem cols2_${index % 2}`;
                let itemComp = (row._componentId) ? listComps_list[row._componentId] : <ImageContainer appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} source={row} removeImage={this.removeImage} numKey={index}/>;
                return (
                  <div className={itemClasses} key={index}>
                    {itemComp}
                  </div>
                )
              })}
              <div ref={(el)=> this._state1_elList638512_endMarker = el} />
            </div>
          </div>
          <div style={botSpacer}/>
        </div>

        <div className="screenFgContainer">
          <div className="foreground">
            <div className='state1_elTopContainer385950' style={style_state1_elTopContainer385950_outer}>
              <div className="" style={style_state1_elTopContainer385950} />
            </div>

            <div className="font-segoeUISemibold  state1_elText948541" style={style_state1_elText948541}>
              <div>{this.props.locStrings.mainpage_text_948541}</div>
            </div>
            <div className='state1_elBottomContainer628788' style={style_state1_elBottomContainer628788_outer}>
              <div className="" style={style_state1_elBottomContainer628788} />
            </div>

            <button id="test22" className="actionFont state1_elButton365297" style={style_state1_elButton365297} onClick={this.onClick_AddPhoto}>
              Add
            </button>
            <input id="Test33" hidden type="file" style={style_state1_elButton365297} accept="image/*" onChange={this.onChangeClick0}/>
            <button className="actionFont state1_elButtonCopy615817" style={style_state1_elButtonCopy615817} onClick={this.onClick_SubmitPhotos} >
              {this.props.locStrings.mainpage_button_615817}
            </button>
            {GrayScreen}
            {LoadingCircle}
          </div>
        </div>
      </div>
    )
  }

  // --- Functions for component state index 2 (3 of 4) ---

  onClick_state2_elButtonCopy615817 = (ev) => {
    let newVal = "2";
    this.props.appActions.updateDataSlot('ds_mainPageState', newVal);

  }

  onClick_DoneClick = (ev) => {
    this.setState({
      images: [],
      actualImages: [],
      croppedImages: [],
      urls: [],
      ids:[]
    })
    let newVal = "3";
    this.props.appActions.updateDataSlot('ds_mainPageState', newVal);

  }


  renderState2() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_state2_elBackground35134 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackground35134_outer = {
      backgroundColor: '#feffff',
     };
    const style_state2_elList638512 = {
      height: 'auto',  // This element is in scroll flow
     };

    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};

    const style_state2_elTopContainer385950 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elTopContainer385950_outer = {
      backgroundColor: '#feffff'
     };
    const style_state2_elText948541 = {
      fontSize: 17.1,
      color: '#37404f',
      textAlign: 'center',
     };
    const style_state2_elBottomContainer628788 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBottomContainer628788_outer = {
      backgroundColor: '#feffff'
     };
    const style_state2_elButton365297 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_365297+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '36.000%',
      paddingTop: '35px',
      backgroundPosition: '50% 0%',
      color: '#37404f',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };
    const style_state2_elButtonCopy615817 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_615817+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '38.500%',
      paddingTop: '35px',
      backgroundPosition: '50% 0%',
      color: '#37404f',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elCard_outer = {
      backgroundColor: 'rgba(4, 4, 4, 0.4900)',
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
     };
    const style_state2_elContainerCardCopy = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elContainerCardCopy_outer = {
      backgroundColor: '#feffff'
     };
    const style_state2_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'left',
     };
    const style_state2_elAddPhotoCopy = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 13.3,
      color: 'black',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
      backgroundColor: '#8ee9ff',
      display: 'block',
     };
    const style_state2_elInstructionsTextCopy = {
      fontSize: 14.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      // width: 100%;
     };
    const style_state2_elTitleTextCopy = {
      fontSize: 19.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };

    return (
      <div className="AppScreen MainPageScreen" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight state2_elBackground35134' style={style_state2_elBackground35134_outer}>
            <div className="" style={style_state2_elBackground35134} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className='hasNestedComps state2_elList638512'>
            <div className="" style={style_state2_elList638512}>
              {this.state.images.map((row, index) => {
                let itemClasses = `gridItem cols2_${index % 2}`;
                let itemComp = (row._componentId) ? listComps_list[row._componentId] : <ImageContainer appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} source={row} removeImage={this.removeImage} numKey={index}/>;
                return (
                  <div className={itemClasses} key={row.key}>
                    {itemComp}
                  </div>
                )
              })}
              <div ref={(el)=> this._state2_elList638512_endMarker = el} />
            </div>
          </div>
        </div>

        <div className="screenFgContainer">
          <div className="foreground">
            <div className='state2_elTopContainer385950' style={style_state2_elTopContainer385950_outer}>
              <div className="" style={style_state2_elTopContainer385950} />
            </div>

            <div className="font-segoeUISemibold  state2_elText948541" style={style_state2_elText948541}>
              <div>{this.props.locStrings.mainpage_text_948541}</div>
            </div>
            <div className='state2_elBottomContainer628788' style={style_state2_elBottomContainer628788_outer}>
              <div className="" style={style_state2_elBottomContainer628788} />
            </div>

            <button className="actionFont state2_elButton365297" style={style_state2_elButton365297}>
              {this.props.locStrings.mainpage_button_365297}
            </button>
            <button className="actionFont state2_elButtonCopy615817" style={style_state2_elButtonCopy615817} onClick={this.onClick_state2_elButtonCopy615817} >
              {this.props.locStrings.mainpage_button_615817}
            </button>
            <div className='containerMinHeight state2_elCard' style={style_state2_elCard_outer}>
              <div className="" style={style_state2_elCard} />
            </div>

            <div className='state2_elContainerCardCopy' style={style_state2_elContainerCardCopy_outer}>
              <div className="" style={style_state2_elContainerCardCopy} />
            </div>

            <Button className="actionFont state2_elFab" style={style_state2_elFab}  variant="fab" color="accent" >
              <img src={btn_icon_804089} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            <button className="font-poppinsSemiBold  state2_elAddPhotoCopy" style={style_state2_elAddPhotoCopy} onClick={this.onClick_DoneClick}>
              {this.props.locStrings.mainpage_addphoto_232977}
            </button>
            <div className="font-segoeUI  state2_elInstructionsTextCopy" style={style_state2_elInstructionsTextCopy}>
              <div>Your images have been uploaded</div>
            </div>
            <div className="font-segoeUISemibold  state2_elTitleTextCopy" style={style_state2_elTitleTextCopy}>
              <div>{this.props.locStrings.mainpage_titletext_226931}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // --- Functions for component state index 3 (4 of 4) ---

  onClick_state3_elAddPhoto = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_mainPageState', newVal);

  }

  renderState3() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_state3_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state3_elBackground_outer = {
      backgroundColor: 'rgba(18, 18, 18, 0.4892)',
     };
    const style_state3_elContainerCard_outer = {
      backgroundColor: '#feffff'
     };
    const style_state3_elTitleText = {
      fontSize: 19.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state3_elInstructionsText = {
      fontSize: 14.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state3_elAddPhoto = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 13.3,
      color: 'black',
      textAlign: 'center',
      backgroundColor: '#8ee9ff',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    return (
      <div className="AppScreen MainPageScreen" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight state3_elBackground' style={style_state3_elBackground_outer}>
            <div className="" style={style_state3_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="flowRow flowRow_MainPageScreen_state3_elContainerCard_772422">
          <div className='state3_elContainerCard' style={style_state3_elContainerCard_outer}>
            <div className="" />
          </div>

          </div>
          <div className='state3_elTitleText'>
            <div className="font-segoeUISemibold" style={style_state3_elTitleText}>
              <div>{this.props.locStrings.mainpage_titletext_864521}</div>
            </div>
          </div>

          <div className='state3_elInstructionsText'>
            <div className="font-segoeUI" style={style_state3_elInstructionsText}>
              <div>You can now close this page. If you would like to take more pictures you may choose to do so.</div>
            </div>
          </div>

          <div className='state3_elAddPhoto'>
            <button className="font-poppinsSemiBold" style={style_state3_elAddPhoto} onClick={this.onClick_state3_elAddPhoto} >
              {this.props.locStrings.mainpage_addphoto_740960}
            </button>
          </div>
        </div>

      </div>
    )
  }

  // Render a cropping window for each photo
  renderState4() {
    let layoutFlowStyle = {
      // botSpacer : "100px"
    };
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_state1_elBackground35134 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground35134_outer = {
      backgroundColor: '#feffff',
     };
    const style_state1_elList638512 = {
      height: 'auto',  // This element is in scroll flow
     };

    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};

    const spacer = {
      height: "10px"
    }
    const botSpacer = {
      height: '100px'
    }
    const style_state1_elTopContainer385950 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elTopContainer385950_outer = {
      backgroundColor: '#feffff'
     };
    const style_state1_elText948541 = {
      fontSize: 17.1,
      color: '#37404f',
      textAlign: 'center',
     };
    const style_state1_elBottomContainer628788 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBottomContainer628788_outer = {
      backgroundColor: '#feffff'
     };
    const style_state1_elButton365297 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_365297+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '36.000%',
      paddingTop: '35px',
      backgroundPosition: '50% 0%',
      color: '#37404f',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elButtonCopy615817 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_615817+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '38.500%',
      paddingTop: '35px',
      backgroundPosition: '50% 0%',
      color: '#37404f',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
     const cropButtonStyle = {
       height: '3em',
       width: '3em'
     }

     const GrayStyle = {
      background: 'rgba(169, 169, 169, 1.000)',
      opacity: 0.60,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

     const GrayScreen = this.state.loadingImage ? (
      <div className="GrayScreen" style={GrayStyle}/>
     ) : null;

     const LoadingCircle = this.state.loadingImage ? (
      <ReactLoading type={"spin"} height={'20%'} width={'20%'} className={"Loading"}/>
    ) : null;

    const { crop, croppedImageUrl, images} = this.state;

    return (
      <div>
        { GrayScreen }
        { LoadingCircle }
        <div style={{ height: window.innerHeight * 0.1, boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1 className={'font-segoeUISemibold'} style={{ textAlign: 'center', margin: '0', lineHeight: '1px' }}>Center the mole</h1>
        </div>
        <div style={{ height: window.innerHeight * 0.8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='react-crop-container' style={{ margin: '0 10%', dislay: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            {this.state.images.length > 0 && !this.state.loadingImage && (
              <ReactCrop
                src={images[images.length - 1]}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                minWidth={this.state.minWidth}
                minHeight={this.state.minWidth}
                keepSelection={'true'}
                style={{ maxheight: '100%' }}
              />
            )}
          </div>
        </div>
          <div style={{ position: 'fixed', bottom: '0', height: window.innerHeight * 0.1, width: '100%', background: '#feffff', boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)', display: 'flex', alignItems: 'center' }}>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <img src={xIcon} onClick={this.onClickCancel} alt={'Cancel'} style={cropButtonStyle} />
              <img src={checkIcon} onClick={this.onClickDone} alt={'Done'} style={cropButtonStyle} />
            </div>
          </div>
      </div>
    )
  }

  render() {
    switch (parseInt((this.props.ds_mainPageState !== undefined) ? this.props.ds_mainPageState : 0, 10)) {
      default:
      case 0 && this.state.isActive:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }

}
